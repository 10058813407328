import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import { IRvProperty } from "types";

const Wrapper = styled.div`
  margin: 50px 35px;
  .address {
    font-family: "Colfax-Bold", Arial, Helvetica, sans-serif;
    font-family: Colfax;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    font-weight: bolder;
  }
  .agent-name {
    font-family: "Colfax", Arial, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
  .agent-contact {
    font-family: Colfax;
    font-size: 12px;
    line-height: 15px;
    color: #0094ff;
    font-family: "Colfax", Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: space-between;
    margin-right: 2rem;
  }
`;
const sluggify = (Text:string) => {
  return Text
        .toLowerCase()
        .replace(/[^\w ]+/g,'')
        .replace(/ +/g,'-')
        ;
}
const getMapLink = (providedProperty: IRvProperty) => {
  return `https://www.google.com/maps/search/?api=1&query=${sluggify(providedProperty.address)}&query_place_id=${providedProperty.place_id}`
}
const AppointmentInfo: FunctionComponent<{property: IRvProperty}> = ({property}) => {
  const { id, address, agent_name, agent_phone, time } = property;
  const link = getMapLink(property)
  return (
    <Wrapper>
      <div className="address">
        <a href={link} target="_blank" rel="noopener noreferrer"> {address} </a>
      </div>
      <div className="agent-name">Listing Agent: {agent_name}</div>
      <div className="agent-name datetime">Viewing on: Sept 24 1:20 pm</div>
      <div className="agent-contact">
        <span>{agent_phone}</span>
      </div>
    </Wrapper>
  );
};

export default AppointmentInfo;
