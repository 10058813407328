import React, { useContext } from "react";
import Firebase, { FirebaseContext } from ".firebase";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import Profile from "modules/OnBoarding/Views/Profile";
import Company from "modules/OnBoarding/Views/Company";

export default [
  {
    path: "/on-boarding",
    name: "Onboarding",
    component: Profile,
    exact: true
  }
];

export const OnBoardingRouter = () => {
  const { user, Firebase, revaUser } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
    revaUser: any | null;
  }>(FirebaseContext);
  return (
    <>
      <Route
        exact
        path={["/on-boarding/profile", "/on-boarding"]}
        render={() => {
          let response = <Profile />;
          if (!user) response = <Redirect to={{ pathname: "/login" }} />;
          return response;
        }}
      />
      <Route
        exact
        path={["/on-boarding/company"]}
        render={() => {
          let response = <Company />;
          if (!user) response = <Redirect to={{ pathname: "/login" }} />;
          if (!revaUser)
            response = <Redirect to={{ pathname: "/on-boarding" }} />;
          return response;
        }}
      />
    </>
  );
};
