import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
  FormEvent
} from "react";
import { IRvTour } from "types";
import EditTourContext from "../context";
import Autocomplete from "react-google-autocomplete";
import Firebase, { FirebaseContext } from ".firebase";
import { useParams, useHistory } from "react-router";
import Button from "Components/Button/Button";
import {
  MainContainer,
  HeaderContainer,
  MainAreaContainer,
  FooterContainer
} from "modules/Layout/Layout.module";

export const StartingAddress: FunctionComponent = () => {
  const history = useHistory();
  const { tourId } = useParams();
  const { user, Firebase } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
  }>(FirebaseContext);
  const { activeTour, setActiveTour } = useContext<{
    activeTour: IRvTour | null;
    setActiveTour: React.Dispatch<React.SetStateAction<IRvTour | null>>;
  }>(EditTourContext);
  const [localTour, setLocalTour] = useState(null as IRvTour | null);
  const [makeFirstPropStart, setMakeFirstPropStart] = useState(false as boolean)
  const attemptSaveTour = async () => {
    if (!localTour || !activeTour) return;
    const toursCollection = Firebase.db.collection("tours");
    const savedTourRef: firebase.firestore.DocumentReference = toursCollection.doc(
      localTour.id
    );
    const payload: any = {
      endedTourCreation: new Date().toISOString(),
      first_prop_is_starting_point: makeFirstPropStart
    }
    if (localTour.starting_address) {
      payload.starting_address = localTour.starting_address;
    }
    await savedTourRef.update(payload);
    setActiveTour(localTour);
    history.push(`/edit/${tourId}/`);
  };
  return (
    <MainContainer>
      <HeaderContainer>
        <h1>
          Got it, where should this tour begin? It can be your client’s home,
          your office or the first property
        </h1>
      </HeaderContainer>
      <MainAreaContainer>
        <div className="form-group reva-form-group">
          <Autocomplete
            disabled={makeFirstPropStart}
            className="reva-input"

            onPlaceSelected={(place: any) => {
              const newTour: IRvTour = activeTour
                ? ({
                    ...activeTour,
                    starting_address: {
                      address: place.formatted_address,
                      place_id: place.place_id
                    }
                  } as IRvTour)
                : ({
                    starting_address: {
                      address: place.formatted_address,
                      place_id: place.place_id
                    }
                  } as IRvTour);
              setLocalTour(newTour);
            }}
            types={["address"]}
            componentRestrictions={{ country: "us" }}
          />
          <div
            style={{display: "flex", alignItems: "flex-start", justifyContent: "flex-start", marginTop: 30}}
          >
              <input
                style={{width: 20, height: 20}}
                id="starting"
                name="starting"
                type="checkbox"
                value="1"
                onChange={(e: FormEvent<HTMLInputElement>) => {
                  setMakeFirstPropStart(e.currentTarget.checked)
                  if (activeTour) {
                    setLocalTour({
                      ...activeTour,
                      first_prop_is_starting_point: e.currentTarget.checked
                    });
                  }
                  
                }}
              />
              <label htmlFor="starting" style={{display: 'inline-block', marginLeft: '0.5rem'}}>
                We'll meet at the first property scheduled
              </label>
            </div>
        </div>
      </MainAreaContainer>
      <FooterContainer>
          <Button
            disabled={!localTour || (localTour && !localTour.starting_address && !localTour.first_prop_is_starting_point)}
            onClick={() => {
              attemptSaveTour();
            }}
          >
            SET START POINT
          </Button>
      </FooterContainer>
    </MainContainer>
  );
};

export default StartingAddress;
