import React, { useContext } from "react";
import Firebase, { FirebaseContext } from ".firebase";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Switch,
  Route
} from "react-router-dom";
import { TourList } from "modules/ToursList/view";
export default [
  {
    path: "/tour-list",
    name: "Your tours",
    component: TourList,
    exact: true
  }
];
export const TourListRouter = () => {
  const { user, Firebase, revaUser } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
    revaUser: any | null;
  }>(FirebaseContext);

  return (
    <Route
      exact
      path={["/tour-list", "/"]}
      render={() => {
        const revaUserComplete = revaUser && revaUser.first_name && revaUser.company;
        let response = <TourList />;
        if (!user) response = <Redirect to={{ pathname: "/login" }} />;
        if (!revaUserComplete) response = <Redirect to={{ pathname: "/on-boarding" }} />;
        return response;
      }}
    />
  );
};
