import React, { useEffect, useState } from "react";
import { FirebaseInstance } from ".firebase";
import { IRvAppointment } from 'types';

export interface appt {
  time: {
    seconds: number;
  }
}
export const sortAppointments = (appointmentsArr: IRvAppointment[]): IRvAppointment[] => {
  const sortingFunction = (firstApp:IRvAppointment, secondApp: IRvAppointment) => {
    try {
      if (firstApp.time.seconds < secondApp.time.seconds) return -1
      if (firstApp.time.seconds > secondApp.time.seconds) return 1
      return 0;
    } catch (error) {
      return 0;
    }
  }
  return appointmentsArr.sort(sortingFunction);
};
const useTourList = (userId: string) => {
  const appointmentsCollection = FirebaseInstance.db.collection("appointments");
  const toursCollection = FirebaseInstance.db.collection("tours");
  const propertiesCollection = FirebaseInstance.db.collection("properties");
  const [tourList, setTourList] = useState([] as any[]);

  const retrieveTours = (snapshot: firebase.firestore.QuerySnapshot) => {
    let allTours = snapshot.docs.map(tour => {
      return { id: tour.id, ...tour.data() };
    });
    allTours = allTours.filter((tour: any) => {
      if (
        tour.startDate &&
        tour.client_name &&
        tour.endDate &&
        tour.status
      )
        return {
          ...tour,
          appointments: tour.appointments ? sortAppointments(tour.appointments) : []
        };
    });
    setTourList(allTours);
  };
  useEffect(() => {
    const unsubscribe = toursCollection
      .where("user_id", "==", userId)
      .onSnapshot(retrieveTours);
    return () => unsubscribe();
  }, []);
  return tourList;
};

export default useTourList;
