import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
const ModalViewPort = styled.div`
  display: flex;
  flex: 1;
  background-image: url("/modal_bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin: 2em;
  }
  .continue-preview {
    font-family: "Colfax-Medium", Arial, Helvetica, sans-serif;
    font-size: 18px;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    width: 250px;
    height: 60px;
    background: #ffffff;
    border-radius: 5px;
    color: black;
    align-items: center;
    text-decoration: none;
  }
`;
export const SuccessModal: FunctionComponent = () => {
  return (
    <ModalViewPort>
      <h1>
        Got it! I’ll be calling agents on your behalf and once properties are
        confirmed I’ll will let you know!
      </h1>
      <img src="/check.png" width="120" height="120" />
      <h1>Meanwhile you can create new tours or check the status of others</h1>
      <Link to="/tour-list" className="continue-preview">
        CONTINUE
      </Link>
    </ModalViewPort>
  );
};

export default SuccessModal;
