import styled, { css } from "styled-components";
import React from "react";
import { SummaryPropertyProps } from "../../types";

const MainPink = "#FF12CB";
const Red = "#FF0000";

export const Property = styled.div<SummaryPropertyProps>`
  width: 355px;
  height: 90px;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  margin: 0.25rem;
  ${props =>
    props.isStartingPoint &&
    css`
      height: 63px;
    `}

  .info-wrapper {
    display:flex;
    height:auto;
  }

  .has-dots {
    display: flex;
    flex: 1;
    max-width: 46px;
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: #000000;
      ${props =>
        props.isStartingPoint &&
        css`
          background-color: #FF12CB;
        `}
      margin: auto;
    }
  }
  .has-info {
    flex: 1;
    margin: 11px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .stop-number {
      font-family: "Colfax-Bold", Arial, Helvetica, sans-serif;
      font-size: 12px;
      line-height: 14px;
      color: #b4b4b4;
      text-transform: uppercase;
      font-weight: bolder;
    }
    .address {
      font-family: "Colfax-Bold", Arial, Helvetica, sans-serif;
      font-family: Colfax;
      font-size: 12px;
      line-height: 14px;
      color: #000000;
      font-weight: bolder;
    }
    .agent-name {
      font-family: "Colfax", Arial, Helvetica, sans-serif;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
    }
    .agent-contact {
      font-family: Colfax;
      font-size: 12px;
      line-height: 15px;
      color: #0094ff;
      font-family: "Colfax", Arial, Helvetica, sans-serif;
      display: flex;
      justify-content: space-between;
      margin-right: 2rem;
    }
    .tour-date {
      font-family: "Colfax", Arial, Helvetica, sans-serif;
      font-size: 12px;
      line-height: 15px;
      color: #0094FF;
    }
  }
`;
