import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  margin: 35px;
  margin-top: 0;
  .title {
    font-family: "Colfax-Medium", Arial, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
  .notes {
    margin-top: 15px;
    background: #ffffff;
    border: 0.5px solid #dadada;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 15px;
    font-family: "Colfax", Arial, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
`;
const LockboxInfo: FunctionComponent<{instructions: string | null}> = ({instructions}) => {
  return (
    <Wrapper>
      <div className="title">Lockbox instructions:</div>
      <div className="notes">
        {instructions ? instructions : 'No instructions left'}
      </div>
    </Wrapper>
  );
};

export default LockboxInfo;
