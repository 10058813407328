import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "App/App";
import * as serviceWorker from "./serviceWorker";
import Firebase, { FirebaseContext } from '.firebase';

declare global {
  interface Window { google: any; FS: any; }
}

ReactDOM.render( <App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
