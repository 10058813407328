import React from "react";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Switch,
  Route
} from "react-router-dom";
import LoginView from "./Views/Login";
export default [
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    exact: true
  }
];
export const AuthRouter = () => {
  return (
    <Route exact path="/login">
      <LoginView />
    </Route>
  );
};
