import React, { FunctionComponent, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  DirectionsService,
  DirectionsRenderer
} from "@react-google-maps/api";
import { IRvProperty, IRvStartingPoint, DirectionsRequest, Waypoint } from "types";

const options = {
  zoomControlOptions: {
    position: window.google.maps.ControlPosition.RIGHT_CENTER // ,
    // ...otherOptopns
  }
};

const GMap: FunctionComponent<{
  properties: IRvProperty[];
  starting_adress: IRvStartingPoint;
}> = ({ properties, starting_adress }) => {
  const [response, setresponse] = useState(null as any);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GPLACES_API_KEY // ,
    // ...otherOptions
  });
  if (!properties.length) {
    return null;
  }

  const lastProperty: IRvProperty = properties[properties.length - 1];
  const waypoints: Waypoint[] = properties.map( (property) => {
    return {
      location: {
        placeId: property.place_id,
      },
      stopover: true,
    } as Waypoint;
  });
  waypoints.pop()
  const directionsCallback = (e:any) => {
    if (!e) return;
    setresponse(e);
  };
  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    // const onLoad = React.useCallback(function onLoad(mapInstance: any) {
    //   // do something with map Instance
    // }, []);
    return (
      <GoogleMap
        // required
        id="direction-example"
        // required
        mapContainerStyle={{
          height: "400px",
          width: "100%"
        }}
        options={
          {
            disableDefaultUI: true,
          }
        }
      >
        <DirectionsService
          // required
          options={
            {
              destination: { placeId: lastProperty.place_id },
              origin: { placeId: starting_adress.place_id },
              travelMode: "DRIVING",
              waypoints
            } as DirectionsRequest
          }
          // required
          callback={directionsCallback}
          // optional
          onLoad={(directionsService: DirectionsService) => {
            // console.log(
            //   "DirectionsService onLoad directionsService: ",
            //   directionsService
            // );
          }}
        />
        {response && (
          <div>
            <DirectionsRenderer
              // required
              options={{
                directions: response
              }}
              // optional
              onLoad={directionsRenderer => {
                // console.log(
                //   "DirectionsRenderer onLoad directionsRenderer: ",
                //   directionsRenderer
                // );
              }}
              // optional
              onUnmount={directionsRenderer => {
                // console.log(
                //   "DirectionsRenderer onUnmount directionsRenderer: ",
                //   directionsRenderer
                // );
              }}
            />
          </div>
        )}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <div>Loading...</div>;
};

export default GMap;
