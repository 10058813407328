import React from "react";
import { IRvTour } from "../../../../types";
import { TourRow } from './TourSummary.styles'
const dateFormatter = (pD:Date): {month:string; day:string;} => {
  const now = new Date();
  const ms = now.getMilliseconds() ;
  now.setDate(now.getDate() + Math.random() * 10);
  const onlymonth = {month: 'short' };
  const onlyDay = {day: '2-digit' };
  return {
    month: new Intl.DateTimeFormat('en-US', onlymonth).format(pD),
    day: new Intl.DateTimeFormat('en-US', onlyDay).format(pD),
  }
}
const ctaText = (status:string) => {
  switch (status) {
    case 'scheduling':
      return 'SEE TOUR'
    case 'cancelled':
      return 'CANCELLED'
    case 'draft':
      return 'EDIT DRAFT'
    case 'ready':
      return 'SEE TOUR'
    default:
      return 'CANCELLED'
  }
}
const TourSummary: React.FunctionComponent<{tour: IRvTour, clicked?:any}> = ({tour, clicked}) => {
  const { client_name, properties, status, startDate, id } = tour;
  const formattedDate = dateFormatter(new Date(startDate));
  return (
    <TourRow>
      <TourRow.Date>
        <div className="month">{formattedDate.month}</div>
        <div className="day">{formattedDate.day}</div>
      </TourRow.Date>
      <TourRow.Details>
        <div className="info">
          <div className="info__title">{client_name}</div>
          <div className="info__desc">{properties ? properties.length : 0} Properties</div>
          <div className={"info__status " + status}>{status}</div>
        </div>
        <div className={"action " + status} onClick={ () => clicked(id) } >{ctaText(status)}</div>
      </TourRow.Details>
    </TourRow>
  );
};

export default TourSummary;
