import React, { useContext, useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components'

import Firebase, { FirebaseContext } from ".firebase";
import { useHistory, useLocation } from 'react-router-dom';

const MenuScreen = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #FFF;
  z-index: 11;
  font-family: "Colfax", Arial, Helvetica, sans-serif;
  .user-info {
    padding-left: 35px;
    padding-top: 54px;
    padding-bottom: 13px;
    border-bottom: solid 1px;
    .name {
      font-family: "Colfax-Bold", Arial, Helvetica, sans-serif;
    }
  }
    .logout {
      border: none;
      background: none;
      font-size: 16px;
      font-family: "Colfax-Bold", Arial, Helvetica, sans-serif;
      margin-left: 35px;
      margin-top: 52px;
      padding:0;
      cursor: pointer;
    }
`;
const Navigation = () => {
  const history = useHistory();
  const location = useLocation();

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [backIsVisible, setBackIsVisible] = useState(true)
  const { Firebase, revaUser } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
    revaUser: firebase.firestore.DocumentData;
    setRevaUser: React.Dispatch<any>;
  }>(FirebaseContext);
  useEffect(() => {
    setBackIsVisible(
      !location.pathname.includes('tour-list') 
    )
  }, [location])
  return (
    <div>
      {
        menuIsOpen && (
          <MenuScreen>
            {
              revaUser && (
                <div>
                  <div className="user-info">
                    <div className="name"> {revaUser.first_name} {revaUser.last_name} </div>
                    <div>{revaUser.company}</div>
                    <div>{revaUser.dre_number}</div>
                  </div>
                  <button className="logout" onClick={ (e) => {
                    e.preventDefault()
                    Firebase.logout();
                  } } >Log out</button>
                </div>

              )
            }
            <CloseIcon 
              style={{position: 'absolute', top: 10, right: 10, cursor: 'pointer', fontSize: 30 }}
              onClick={ () => {
                setMenuIsOpen(false)
              } }
            />
          </MenuScreen>
        )
      }
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={ () => { setMenuIsOpen(true) } }
        style={{
          margin: 0,
          top: 10,
          padding: '0.25em',
          position: "absolute",
          left: 10,
          zIndex: 10,
        }}
      >
        <MenuIcon style={{ fontSize: 30}} className="sizeSmall"  />
      </IconButton>
      {
        backIsVisible && (
          <IconButton
            onClick={ () => { history.push("/tour-list"); } }
            style={{
              margin: 0,
              top: 10,
              padding: '0.25em',
              position: "absolute",
              right: 10,
              zIndex: 10,
            }}
          >
            <ArrowBackIcon style={{ fontSize: 30}} className="sizeSmall"  />
          </IconButton>
        )
      }
      
    </div>
  );
};

export default Navigation;
