import React, {FunctionComponent, useContext, useEffect, useState } from "react";
import { match, useParams } from "react-router-dom";
import Firebase, { FirebaseContext } from ".firebase";
import {
  MainContainer,
  HeaderContainer,
  MainAreaContainer,
  FooterContainer
} from "modules/Layout/Layout.module";
import { AppmtProperty, AppointmentInfo, LockboxInfo } from 'modules/Appointment/Components'
import { IRvTour, IRvProperty, IRvAppointment } from "types";
import PropertyItem from "modules/EditTour/Components/PropertyItem";


const AppointmentSummary: FunctionComponent = () => {
  const { user, Firebase, revaUser, setRevaUser } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
    revaUser: firebase.firestore.DocumentData;
    setRevaUser: React.Dispatch<any>;
  }>(FirebaseContext);
  const { tourId, propertyId } = useParams();
  const toursCollection = Firebase.db.collection("tours");
  const propertiesCollection = Firebase.db.collection("properties");
  const [localTour, setLocalTour] = useState(null as null | IRvTour);
  const [selectedAppointment, setSelectedAppointment] = useState(null as null | IRvAppointment)
  const [selectedProperty, setSelectedProperty] = useState(null as null | IRvProperty)
  const getProperty = async (propvidedPropertyId: string) => {
    const savedPropertyRef: firebase.firestore.DocumentReference = propertiesCollection.doc(
      propvidedPropertyId
    );
    const savedPropertyDoc: firebase.firestore.DocumentSnapshot = await savedPropertyRef.get();
    const propertyData = savedPropertyDoc.data() as IRvProperty;
    setSelectedProperty(propertyData)
    return propertyData;
  };
  const getAppointment = () => {
    if (!localTour || !propertyId) return;
    const { appointments } = localTour;
    const foundAppointment = appointments.find( (appmt) => appmt.property_listing_id === propertyId )
    if (foundAppointment) {
      setSelectedAppointment(foundAppointment);
    }
  };
  
  const fetchTourAndUpdateLocal = async () => {
    const savedTourRef: firebase.firestore.DocumentReference = toursCollection.doc(
      tourId
    );
    const savedTourDoc: firebase.firestore.DocumentSnapshot = await savedTourRef.get();
    const tourData = savedTourDoc.data() as IRvTour;
    setLocalTour(tourData);
  };
  useEffect(() => {
    fetchTourAndUpdateLocal();
  }, [tourId]);
  useEffect(() => {
    if (localTour && propertyId) getAppointment();
    if (propertyId) getProperty(propertyId);
  }, [localTour, propertyId])
  return (
    <MainContainer>
      <MainAreaContainer>
        {selectedProperty && (
          <AppointmentInfo property={selectedProperty} />
        )}
        {selectedAppointment && (
          <LockboxInfo instructions={selectedAppointment.lockbox_text_note} />
        )}
        {selectedProperty && (
          <PropertyItem property={selectedProperty} activeTour={localTour} />
        )}
      </MainAreaContainer>
    </MainContainer>
  );
};

export default AppointmentSummary;
