import { useState } from "react";
import datafinity from "API/datafinity";
import { AxiosResponse } from "axios";
import { IDatafinityProperty, IRvProperty } from "types";
import { v4 as uuidv4 } from "uuid";
import abbr from "common/abbr";
import { number } from "prop-types";
export default () => {
  const addAbbr = (address: string) => {
    let newAddress = address;
    const abbrs: any = abbr;
    const words = address.split(" ");
    const lastWord = address.split(" ").pop();
    if (!lastWord) return "";
    const key: any = lastWord.trim().toUpperCase();
    const abbreviation: any | undefined = '';
    if (typeof abbreviation !== 'undefined') {
      const newWords = words.slice();
      newWords.pop();
      newAddress = [...newWords, abbreviation].join(" ");
    }
    return newAddress;
  };
  const getQuery = (address: string, unit: string | number | null) => {
    const abbreviated = addAbbr(address);
    const processed = unit ? abbreviated : address;
    const withUnit = unit ? `AND "${unit}"` : "";
    return `address:("${processed}" ${withUnit} ) AND city:("SAN DIEGO") AND country:US`;
  };
  const [property, setProperty] = useState<IDatafinityProperty | any>(null);
  const [notFound, setNotFound] = useState(null as null | boolean);
  const searchProperty = async (payload: {
    address: string;
    unit: string | number | null;
  }) => {
    const { address, unit } = payload;
    console.log("Searching in MLS");
    console.log(address);
    const response: AxiosResponse<{
      records: IDatafinityProperty[];
    }> = await datafinity.post("/properties/search", {
      query: getQuery(address, unit),
      format: "JSON",
      num_records: 5,
      download: false
    });
    if (
      response &&
      response.data &&
      response.data.records &&
      response.data.records.length
    ) {
      setNotFound(false);
      setProperty(response.data.records[0]);
      return;
    }
    setNotFound(true);
    setProperty(null);
    return;
    // const newResponse = await datafinity.post("/properties/search", {
    //   query: getQuery("2253 5Th Ave"),
    //   format: "JSON",
    //   num_records: 5,
    //   download: false
    // });
    // setProperty(newResponse.data.records[0]);
  };
  const transformProperty = (
    rawProperty: IDatafinityProperty,
    placeId: string
  ): IRvProperty => {
    const prices: number[] = rawProperty.prices
      ? rawProperty.prices.map(p => (p ? p.amountMax : 0))
      : [0];
    const price = Math.max(...prices);
    const number_of_bathrooms: number = rawProperty.numBathroom || 0;
    const number_of_bedrooms: number = rawProperty.numBedroom || 0;
    const sqft =
      (rawProperty.lotSizeValue || 0) + (rawProperty.floorSizeValue || 0);
    const image =
      rawProperty.imageURLs && rawProperty.imageURLs.length
        ? rawProperty.imageURLs[0]
        : "";
    return {
      id: uuidv4(),
      address: rawProperty.address || "No address",
      city: rawProperty.city || "",
      mls_number: rawProperty.mlsNumber || "",
      price,
      number_of_bathrooms,
      number_of_bedrooms,
      place_id: placeId,
      type: rawProperty.propertyType || null,
      sqft,
      image,
      zip_code: rawProperty.postalCode,
    } as IRvProperty;
  };
  return [
    property,
    searchProperty,
    setProperty,
    transformProperty,
    notFound,
    setNotFound
  ];
};
