import React, { useContext } from "react";
import Firebase, { FirebaseContext } from ".firebase";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Switch,
  Route
} from "react-router-dom";
import { Layout } from "modules/Layout/Layout.module";
export const LayoutRoutes =  [
  {
    path: "/layout",
    name: "Layout",
    component: Layout,
    exact: true
  }
];
export const LayoutRouter = () => {
  return (
    <Route
      path={["/layout"]}
      render={({match}) => {
        let response = <Layout match={match} />;
        return response
      }}
    />
  );
};
