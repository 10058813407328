import { useHistory } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import Firebase, { FirebaseContext } from ".firebase";
import Button from "Components/Button/Button";
import styled from "styled-components";

const LoginView: React.FunctionComponent = () => {
  let history = useHistory();
  const { user, Firebase } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
  }>(FirebaseContext);
  useEffect(() => {
    if (user && user.uid) {
      try {
        window.FS.identify(user.uid, {
          displayName: user.displayName,
          email: user.email
        });
      } catch (error) {
        console.error(error);
      }
      return history.replace("/tour-list");
    }
    return () => {
      console.log("clean");
    };
  }, [history, user]);
  const attemptLoginGoogle = async () => {
    await Firebase.loginGoogle();
  };
  const attemptLoginFacebook = async () => {
    await Firebase.loginFacebook();
  };
  const LoginButtonStyles = styled(Button)`
    -webkit-border-radius: 1px;
    border-radius: 1px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background-color .218s,border-color .218s,box-shadow .218s;
    transition: background-color .218s,border-color .218s,box-shadow .218s;
    -webkit-user-select: none;
    -webkit-appearance: none;
    background-color: #fff;
    color: #262626;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    background-image: url("./sign_in_google.png");
    background-position: center center;
    background-size: cover;
    color: transparent;
  `
  const FacebookButton = styled(LoginButtonStyles)`
    background-image: url("./continue_fb.png");
    width: 247px;
    height: 40px;
    margin: 1em;

  `
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <img src="./splash600.png" width="375" alt="Reva" />
      <LoginButtonStyles onClick={attemptLoginGoogle}>Login With Google</LoginButtonStyles>
      <FacebookButton onClick={attemptLoginFacebook} >Login With Facebook</FacebookButton>
    </div>
  );
};
export default LoginView;
