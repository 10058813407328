import React, { useState, FormEvent, useContext } from "react";
import usePropertySearch from "../Hooks/usePropertySearch";
import Firebase, { FirebaseInstance, FirebaseContext } from ".firebase";
import { IDatafinityProperty, IRvProperty, IRvStartingPoint } from "types";
import { IRvTour } from "types";
import EditTourContext from "../context";
import { useHistory, match } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import Button from "Components/Button/Button";
import styled from "styled-components";
import { MainContainer, FooterContainer, HeaderContainer } from "modules/Layout/Layout.module";
import { initialState } from 'modules/EditTour/State/initialState'

const BtnWrapper = styled.div<any>`
  display: flex;
  margin-top: 2em;
  button {
    padding: 0 1em;
    margin: 0.5em;
    margin-top: 0.5em;
    text-transform: uppercase;
    font-size: 14px;
  }
`;
const NewProperty: React.FunctionComponent<{
  match: match;
  tourId: string;
}> = ({ tourId, match }) => {
  const history = useHistory();
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const { user, Firebase } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
  }>(FirebaseContext);
  const { activeTour, setActiveTour } = useContext<{
    activeTour: IRvTour | null;
    setActiveTour: React.Dispatch<React.SetStateAction<IRvTour | null>>;
  }>(EditTourContext);
  const [
    property,
    searchProperty,
    setProperty,
    transformProperty,
    notFound,
    setNotFound
  ] = usePropertySearch();
  const propertiesCollection = Firebase.db.collection("properties");
  const toursCollection = Firebase.db.collection("tours");
  const [placeId, setplaceId] = useState("");
  const [place, setPlace] = useState("" as any)
  const [unit, setUnit] = useState("")
  const [startingAddress, setStartingAddress] = useState(null as null | IRvStartingPoint)
  const [datafinityPayload, setDatafinityPayload] = useState({
    address: null,
    unit: null
  } as {
    address: string | null;
    unit: string | null;
  });
  const saveImage = (imageURL: string | undefined, propertyId: string) => {
    if (!imageURL) return;
    return {imageURL, propertyId};
  };
  const savePropertyToTour = async (providedSavedProperty: IRvProperty) => {
    if (!activeTour) return false;
    const propertyId = providedSavedProperty.id
    const savedTourRef: firebase.firestore.DocumentReference = toursCollection.doc(
      activeTour.id
    );
    const savedTourDoc: firebase.firestore.DocumentSnapshot = await savedTourRef.get();
    const tourData = savedTourDoc.data();
    const appointments = tourData ? tourData.appointments || [] : [];
    const thisPropertyIsStartingPoint = 
      !activeTour.starting_address 
      && activeTour.first_prop_is_starting_point
      && !appointments.length
    setLastUpdate(new Date());
    const newTourData = {
      endedTourCreation: lastUpdate.getTime(),
      appointments: [
        ...appointments,
        {
          tour_id: activeTour.id,
          property_listing_id: propertyId,
          time: null,
          lockbox_audio_note_uri: null,
          lockbox_text_note: null
        }
      ]
    } as any;
    if (thisPropertyIsStartingPoint) {
      newTourData.starting_address = {
        address: providedSavedProperty.address,
        place_id: providedSavedProperty.place_id
      }
    }
    savedTourRef.update(newTourData);
    let newUrl = match.url.replace("add_property", "edit_property") + `/${propertyId}`
    newUrl = newUrl.replace('edit_property//', 'edit_property/')
    history.push(newUrl);
  };
  const addDatafinityProperty = async (property: IDatafinityProperty) => {
    const transformedProperty: IRvProperty = transformProperty(
      property,
      placeId
    );

    const localSavedProperty: firebase.firestore.DocumentReference = await propertiesCollection.add(
      {...transformedProperty, unit}
    );
    saveImage(transformedProperty.image, localSavedProperty.id);
    await localSavedProperty.update({ id: localSavedProperty.id });
    savePropertyToTour({...transformedProperty, id: localSavedProperty.id} as IRvProperty)
  };
  const addEmptyProperty = async () => {
    if (!place || !placeId) throw new Error('no place id')
    const emptyProperty:IRvProperty = {
      ...initialState,
      address: place.address_components[0].long_name + " " + place.address_components[1].long_name,
      place_id: placeId,
      city: place ? place.address_components[4].long_name : '',
      state: place ? place.address_components[5].long_name : '',
      unit,
    }
    const localSavedProperty: firebase.firestore.DocumentReference = await propertiesCollection.add(
      emptyProperty
    );
    emptyProperty.id = localSavedProperty.id;
    await localSavedProperty.update({ id: localSavedProperty.id });
    savePropertyToTour({...emptyProperty, id: localSavedProperty.id} as IRvProperty)
    
  }

  return (
    <MainContainer>
        <>
          <HeaderContainer>
            <h1>Alright. What is the address of property you would like to add to the tour?</h1>
          </HeaderContainer>
          <div className="form-group reva-form-group">
            <Autocomplete
              className="reva-input"
              onPlaceSelected={(place: any) => {
                setNotFound(false);
                const address =
                  place.address_components[0].long_name +
                  " " +
                  place.address_components[1].long_name;
                setDatafinityPayload({
                  address,
                  unit: datafinityPayload.unit
                });
                setNotFound(null);
                searchProperty({
                  address,
                  unit: datafinityPayload.unit
                });
                setStartingAddress({
                  address,
                  place_id: place.place_id
                })
                setplaceId(place.place_id);
                setPlace(place)
              }}
              types={"address"}
              componentRestrictions={{ country: "us" }}
            />
            <h1>Unit number ?</h1>
            <input
              className="reva-input"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                setDatafinityPayload({
                  address: datafinityPayload.address,
                  unit: e.currentTarget.value
                });
                setNotFound(null);
                searchProperty({
                  address: datafinityPayload.address,
                  unit: e.currentTarget.value
                });
                setUnit(e.currentTarget.value)
              }}
            />
          </div>
        </>
        <FooterContainer>
          {
            notFound !== null && (
              <BtnWrapper>
                <Button
                  onClick={() => {
                    if (property) {
                      addDatafinityProperty(property);
                    } else {
                      addEmptyProperty()
                    }
                  }}
                >
                  Add Property
                </Button>
              </BtnWrapper>

            )
          }
        </FooterContainer>
    </MainContainer>
  );
};

export default NewProperty;
