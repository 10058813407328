import * as React from "react";
import MainBtn from "./Button.styles";
import { MainBtnProps } from "./types";
const Button: React.FunctionComponent<MainBtnProps> = props => (
  <MainBtn
    className={`reva-button ${props.className || ""} `}
    inverted={props.inverted}
    disabled={props.disabled}
    style={{...props.style}}
    onClick={props.onClick}
    type="button"
  >
    {props.children}
  </MainBtn>
);
Button.defaultProps = {
  children: null,
  onClick: () => {}
};
export default Button;
