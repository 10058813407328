import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext
} from "react";
import { Route, match } from "react-router-dom";
import ClientView from "./Views/Client.view";
import TourDate from "./Views/TourDate.view";
import { useHistory } from "react-router";
import { loadPartialConfig } from "@babel/core";
import Firebase, { FirebaseContext } from ".firebase";
import { IRvTour } from "types";
import NewTourContext from "./context";
const NewTour: FunctionComponent<{ match: match }> = ({ match }) => {
  const history = useHistory();
  const [newTour, setNewTour] = useState(null as null | IRvTour);
  useEffect(() => {
    if (
      !match.path.includes("client-name") ||
      !match.path.includes("select-date")
    ) {
      history.replace(`${match.path}/client-name`);
    }
    return () => {};
  }, [match.path]);

  return (
    <NewTourContext.Provider value={{newTour, setNewTour}}>
      <Route path={`${match.path}/client-name`} component={ClientView} />
      <Route path={`${match.path}/select-date`} component={TourDate} />
    </NewTourContext.Provider>
  );
};

export default NewTour;
