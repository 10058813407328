import { IRvProperty } from "types";
export const initialState = {
  id: "",
  lot_size_sqft: 0,
  mls_number: "",
  description: "",
  listingPrice: 0,
  name: "",
  agent_phone: "",
  address: "",
  city: "San Diego",
  state: "California",
  unit: "",
  zip_code: "",
  type: "",
  price: 0,
  floor_size_sqft: 0,
  number_of_bedrooms: 0,
  number_of_bathrooms: 0,
  parkin_details: "",
  sqft: 0,
  place_id: null
} as IRvProperty;