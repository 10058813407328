import React, { FunctionComponent } from 'react';
import { match } from 'react-router-dom';
import AppointmentSummary from 'modules/Appointment/Views/AppointmentSummary';

const Appointment: FunctionComponent<{ match: match }> = ({ match }) => {
  return (
    <AppointmentSummary></AppointmentSummary>
  )
};

export default Appointment;