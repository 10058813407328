import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext
} from "react";
import { Route, match } from "react-router-dom";
// import ClientView from "./Views/Client.view";
// import TourDate from "./Views/TourDate.view";
import { useHistory, useParams } from "react-router";
import { loadPartialConfig } from "@babel/core";
import Firebase, { FirebaseContext } from ".firebase";
import { IRvTour } from "types";
import EditTourContext from "./context";
import StartingAddress from "./Views/StartingAddres";
import NewProperty from "./Views/NewProperty";
import PropertyList from "./Views/PropertyList";
import EditProperty from "./Views/EditProperty";
import { PreviewTour } from "./Views/PreviewTour";
const EditTourContainer: FunctionComponent<{ match: match }> = ({ match }) => {
  const history = useHistory();
  const { tourId } = useParams();
  const [activeTour, setActiveTour] = useState(null as IRvTour | null)
  const { user, Firebase } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
  }>(FirebaseContext);
  const toursCollection = Firebase.db.collection("tours");
  const propertiesCollection = Firebase.db.collection("properties");
  const fetchTourAndUpdateLocal = async () => {
    const savedTourRef: firebase.firestore.DocumentReference = toursCollection.doc(
      tourId
    );
    const savedTourDoc: firebase.firestore.DocumentSnapshot = await savedTourRef.get();
    const tourData = savedTourDoc.data() as IRvTour;
    setActiveTour({
      id: savedTourDoc.id,
      ...tourData
    });
    
  };
  useEffect(() => {
    //get tour//
    fetchTourAndUpdateLocal();

    return () => {
      //cleanup//
    };
  }, [tourId]);
  useEffect(() => {
    if (activeTour) {
      const noProperties = !(activeTour.appointments && activeTour.appointments.length);
      const noStartingAddress = !activeTour.starting_address && !activeTour.first_prop_is_starting_point;
      let redirectTo = noStartingAddress ? 'starting_address' : noProperties ? 'add_property' : 'review'
      history.replace(`${match.url}/${redirectTo}`)
    }
  }, [activeTour, history, match.url])
  return (
    <EditTourContext.Provider value={{activeTour, setActiveTour}}>
      <Route path={`${match.path}/starting_address`} component={StartingAddress} />
      <Route path={`${match.path}/add_property`} render={({match}) => {
        return <NewProperty match={match} tourId={tourId as string} />
      }} />
      <Route path={`${match.path}/property_list`} component={PropertyList} />
      <Route path={`${match.path}/review`} render={({match}) => {
        return <PreviewTour match={match} tourId={tourId as string} />
      }} />
      <Route path={`${match.path}/edit_property/:propertyId`} render={({match}) => {
        return <EditProperty match={match} tourId={tourId as string} />
      }} />
    </EditTourContext.Provider>
  );
};

export default EditTourContainer;
