import React, { useContext, useState, useEffect } from "react";
import { match } from "react-router-dom";
import { useParams, useHistory } from "react-router";

import { IRvProperty } from "types";
import Firebase, { FirebaseContext } from ".firebase";
import PropertyForm from "../Components/PropertyForm";

const EditProperty: React.FunctionComponent<{
  match: match;
  tourId: string;
}> = ({ tourId, match }) => {
  const history = useHistory()
  const { Firebase } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
    revaUser: firebase.firestore.DocumentData;
    setRevaUser: React.Dispatch<any>;
  }>(FirebaseContext);
  const { propertyId } = useParams();

  const [selectedProperty, setSelectedProperty] = useState(null as null | IRvProperty)
  const propertiesCollection = Firebase.db.collection("properties");
  
  const addPropertyFromForm = async (property: IRvProperty) => {
    const savedPropertyRef: firebase.firestore.DocumentReference = propertiesCollection.doc(
      propertyId
    );
    await savedPropertyRef.update({ ...property })
    history.push(`/edit/${tourId}/review`);
  };
  
  useEffect(() => {
    if (propertyId) {
      const getProperty = async (propvidedPropertyId: string) => {
        const savedPropertyRef: firebase.firestore.DocumentReference = propertiesCollection.doc(
          propvidedPropertyId
        );
        const savedPropertyDoc: firebase.firestore.DocumentSnapshot = await savedPropertyRef.get();
        const propertyData = savedPropertyDoc.data() as IRvProperty;
        setSelectedProperty(propertyData)
        return propertyData;
      };
      getProperty(propertyId)
    }
  }, [propertyId])
  useEffect(() => {
    console.log('selectedProperty');
  }, [selectedProperty])
  return (
    <PropertyForm onSubmit={addPropertyFromForm} savedProperty={selectedProperty} />
  )
}

export default EditProperty