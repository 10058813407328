import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Switch,
  Route
} from "react-router-dom";
import RouteWithSubRoutes from "common/RouteWithSubRoutes";
import appRoutes from "App/routes";
import Firebase, { FirebaseContext, FirebaseInstance } from ".firebase/";
import Header from "Components/Header/Header";
import useAuth from "modules/Auth/Hooks/useAuth.hook";
import { ScreenWrapper } from "modules/ToursList/components/ScreenWrapper/ScreenWrapper";
/* ROUTERS */
import { AuthRouter } from "modules/Auth/routes";
import { TourListRouter } from "modules/ToursList/routes";
import { TourDetailRouter } from "modules/TourDetails/routes";
import { NewTourRouter } from "modules/NewTour/routes";
import { EditTourRouter } from "modules/EditTour/routes";
import { OnBoardingRouter } from "modules/OnBoarding/routes";
import { LayoutRouter } from "modules/Layout/routes/router";
import { AppointmentRouter } from "modules/Appointment/routes";
/* ROUTERS */

const App: React.FunctionComponent = () => {
  const { user }: { user: firebase.User; revaUser: any | null } = useAuth();
  const [revaUser, setRevaUser] = useState(null as any);
  const attemptLoadProfile = async () => {
    if (!user || !user.uid) return false;
    const usersCollection = FirebaseInstance.db.collection("users");
    const providerUserQuery: firebase.firestore.QuerySnapshot = await usersCollection
      .where("provider_id", "==", user.uid)
      .limit(1)
      .get();
    if (!providerUserQuery.docs.length) return false;
    setRevaUser({
      id: providerUserQuery.docs[0].id,
      ...providerUserQuery.docs[0].data()
    });
  };

  useEffect(() => {
    attemptLoadProfile();
    // read users collection
    return () => {};
  }, [user]);

  return (
    <Router>
      <FirebaseContext.Provider
        value={{ user, revaUser, setRevaUser, Firebase: FirebaseInstance }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative"
          }}
        >
          {user && <Header />}
          <TourListRouter />
          <AuthRouter />
          <TourDetailRouter />
          <NewTourRouter />
          <OnBoardingRouter />
          <EditTourRouter />
          <LayoutRouter />
          <AppointmentRouter />
        </div>
      </FirebaseContext.Provider>
    </Router>
  );
};
export default App;
