import React, { useContext, useEffect, useState } from "react";
import Firebase, { FirebaseContext } from ".firebase";
import styled, { css } from "styled-components";
import Button from "Components/Button/Button";
import TourListState from "./state";
import TourSummary from "./components/TourSummary/TourSummary";
import SearchBox from "./components/SearchBox/SearchBox";
import useTourList from "modules/ToursList/Hooks/useTourList.hook";
import { IRvTour } from "types";
import { Link, useHistory } from "react-router-dom";
import {
  MainContainer,
  HeaderContainer,
  MainAreaContainer,
  FooterContainer
} from "modules/Layout/Layout.module";
import CancelModal from "modules/EditTour/Components/CancelModal";
const TourListFooter = styled.div`
  margin-top: auto;
  height: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(360deg, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 1.5rem;
`;
const ScrollableDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 540px;
  flex-grow: 0;
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  margin-top: 1em;
`;
export const TourList = () => {
  const history = useHistory();
  // history.replace('layout')
  const { user, Firebase } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
  }>(FirebaseContext);
  const tourList: Array<any> = useTourList(user.uid);
  const toursCollection = Firebase.db.collection("tours");
  if (!tourList || !tourList.length) {
    // history.push('/new-tour');
  }
  const [cancellableTour, setCancellableTour] = useState(
    false as boolean | firebase.firestore.DocumentReference
  );
  const cancelTour = async (tourId: string) => {
    const savedTourRef: firebase.firestore.DocumentReference = toursCollection.doc(
      tourId
    );
    // savedTourRef.update({
    //   status: "cancelled"
    // });
    setCancellableTour(savedTourRef);
  };
  const reopenTour = async (tourId: string) => {
    const savedTourRef: firebase.firestore.DocumentReference = toursCollection.doc(
      tourId
    );
    savedTourRef.update({
      status: "draft"
    });
  };
  return (
    <MainContainer>
      {cancellableTour && (
        <CancelModal
          closeModalFn={() => {
            setCancellableTour(false);
          }}
          cancellableTour={
            cancellableTour as firebase.firestore.DocumentReference
          }
        />
      )}
      <HeaderContainer>
        <h1>Tour List</h1>
      </HeaderContainer>
      {/* <SearchBox /> */}
      <MainAreaContainer>
        <ScrollableDiv>
          {tourList.filter(tour => tour.status !== 'cancelled').map((tour, i) => {
            const sTour = {
              id: tour.id,
              client_name: tour.client_name,
              startDate: tour.startDate,
              starting_address: {
                id: "1",
                address: tour.starting_address
              },
              status: tour.status,
              properties: tour.appointments
            } as IRvTour;
            return (
              <TourSummary
                key={tour.id}
                tour={sTour}
                clicked={(id: string | number) => {
                  const { status } = tour;
                  switch (status) {
                    case "ready":
                      return history.push(`/detail/${sTour.id}`);
                    case "draft":
                      return history.push(`/edit/${sTour.id}`);
                    case "scheduling":
                      return history.push(`/detail/${sTour.id}`);
                    case "cancelled":
                      return 
                    default:
                      return;
                  }
                }}
              />
            );
          })}
        </ScrollableDiv>
      </MainAreaContainer>
      <FooterContainer>
        <TourListFooter>
          <Button
            onClick={() => {
              history.push("/new-tour");
            }}
          >
            + CREATE NEW TOUR
          </Button>
        </TourListFooter>
      </FooterContainer>
    </MainContainer>
  );
};
