import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext
} from "react";
import {
  BrowserRouter as Router,
  Link,
  useHistory,
  useParams
} from "react-router-dom";

import Firebase, { FirebaseContext } from ".firebase";
import { IRvTour, IRvProperty } from "types";
import EditTourContext from "../context";
import PropertyItem from "../Components/PropertyItem";
import styled from "styled-components";
import Button from "Components/Button/Button";
import {
  MainContainer,
  HeaderContainer,
  MainAreaContainer,
  FooterContainer
} from "modules/Layout/Layout.module";

const ScrollableDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 540px;
  flex-grow: 0;
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  margin-top: 1em;
`;
export const PropertyList: FunctionComponent = () => {
  const history = useHistory();
  const { tourId } = useParams();
  const { user, Firebase } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
  }>(FirebaseContext);
  const { activeTour, setActiveTour } = useContext<{
    activeTour: IRvTour | null;
    setActiveTour: React.Dispatch<React.SetStateAction<IRvTour | null>>;
  }>(EditTourContext);
  const toursCollection = Firebase.db.collection("tours");
  const [showModal, setShowModal] = useState(false);
  const finishEditing = async () => {
    if (!activeTour) return false;
    const savedTourRef: firebase.firestore.DocumentReference = toursCollection.doc(
      activeTour.id
    );
    await savedTourRef.update({
      endedTourCreation: new Date().toISOString(),
      status: "scheduling"
    });
    // history.push("/tour-list");
    setShowModal(true);
  };
  const toReview = activeTour ? `/edit/${activeTour.id}/review` : "/";
  const toAdd = activeTour ? `/edit/${activeTour.id}/add_property` : "/";
  const toStarting = activeTour ? `/edit/${activeTour.id}/starting_address` : "/";
  const propertiesCollection = Firebase.db.collection("properties");
  const [localTour, setLocalTour] = useState(null as null | IRvTour);
  
  const getProperty = async (propertyId: string) => {
    const savedPropertyRef: firebase.firestore.DocumentReference = propertiesCollection.doc(
      propertyId
    );
    const savedPropertyDoc: firebase.firestore.DocumentSnapshot = await savedPropertyRef.get();
    const propertyData = savedPropertyDoc.data() as IRvProperty;
    return propertyData;
  };
  const fetchTourAndUpdateLocal = async () => {
    const savedTourRef: firebase.firestore.DocumentReference = toursCollection.doc(
      tourId
    );
    const savedTourDoc: firebase.firestore.DocumentSnapshot = await savedTourRef.get();
    const tourData = savedTourDoc.data() as IRvTour;
    const propertiesPromises: Promise<
      IRvProperty
    >[] = tourData.appointments.map(async apptmnt => {
      const property = await getProperty(apptmnt.property_listing_id);
      return property;
    });
    const properties = await Promise.all(propertiesPromises);
    setLocalTour({
      ...tourData,
      properties
    });
  };
  useEffect(() => {
    
    //get tour//
    
    if (tourId && toursCollection) {
      fetchTourAndUpdateLocal()
    }
    return () => {
      //cleanup//
    };
  }, [tourId]);
  return (
    <MainContainer>
      <HeaderContainer>
        <h1>Got it. I've added these properties to the tour:</h1>
      </HeaderContainer>
      <MainAreaContainer>
        {localTour && localTour.properties.length && (
          <div>
            <ScrollableDiv>
              <div style={{ paddingBottom: "5rem" }}>
                {localTour.properties.map((property, index) => (
                  <PropertyItem property={property} activeTour={localTour} />
                ))}
              </div>
            </ScrollableDiv>
          </div>
        )}
      </MainAreaContainer>
      <FooterContainer>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link className="reva-button inverted" to={toReview}>
            DONE
          </Link>
          <Link className="reva-button" to={toAdd}>
            ADD OTHER
          </Link>
        </div>
      </FooterContainer>
      {showModal && (
        <div>
          <h1>
            Got it! I’ll be calling agents on your behalf and once properties
            are confirmed I’ll will let you know!
          </h1>
          <Link to="/tour-list">Continue</Link>
        </div>
      )}
    </MainContainer>
  );
};
export default PropertyList;
