import styled, { css } from "styled-components";
import { MainBtnProps } from "./types";

const MainBtn = styled.button<MainBtnProps>`
  display: flex;
  justify-content:center;
  align-items: center;
  background-color: #ff12cb;
  width: auto;
  padding: 0rem 3rem;
  height: 60px;
  border: none;
  border-radius: 3px;
  font-family: "Colfax-Medium", Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  cursor: pointer;
  margin: auto auto 0 auto;
  &:hover {
    /* opacity: 0.5 */
  }

  ${props =>
    props.primary &&
    css`
      background-color: #ff12cb;
      color: white;
    `}
  ${props =>
    props.inverted &&
    css`
      background-color: white !important;
      color: #ff12cb !important;
      border: 1px solid #ff12cb;
    `}
  ${props =>
    props.disabled &&
    css`
      background-color: gray !important;
      color: white !important;
      cursor: default;
    `}
`;

export default MainBtn;
