import React, { useContext, useEffect, useState } from "react";
import PropertySummary from "./components/PropertySummary/PropertySummary";
import { useParams, useHistory } from "react-router-dom";
import { Location } from "history";
import Firebase, { FirebaseContext } from ".firebase";
import { IRvTour, IRvProperty } from "types";
import styled from "styled-components";
import GMap from "Components/Gmap";
import { StartingPoint } from 'modules/TourDetails/components/StartingPoint'

 //tourDetailStyled
 //This object is a container which applies SASS style attributes to the TourDetail object contained therein
const tourDetailStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

//TourDetail
//This object retrieves and displays the details of a Tour
export const TourDetail = () => {
  //useHistory is a React Hook that holds and tracks browser history
  //Used to allow undo/redo functionality
  const history = useHistory();
  //Get user data from the Firebase database
  
  const { user, Firebase, revaUser, setRevaUser } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
    revaUser: firebase.firestore.DocumentData;
    setRevaUser: React.Dispatch<any>;
  }>(FirebaseContext);

  //useParams is a React Hook that holds Route parameters which are set dynamically in the URL
  //tourId is a long string that holds the ID string of a particular Tour
  const { tourId } = useParams();
  //Get all the Tours saved for this user
  const toursCollection = Firebase.db.collection("tours");
  //Get all the properties listed in the user's Tours
  const propertiesCollection = Firebase.db.collection("properties");
  //useState is a React Hook that holds the app's current parameter values; it returns a tuple
  //localTour is the current state of the app's parameters; setLocalTour is the method by which the value of localTour is updated
  //The default state is of null value and type IRvTour
  const [localTour, setLocalTour] = useState(null as null | IRvTour);
  
  //get the data for a specific property via asynchronous function
  const getProperty = async (propertyId: string) => {
    //Get the id of the property for which to get data
    const savedPropertyRef: firebase.firestore.DocumentReference = propertiesCollection.doc(
      propertyId
    );
    //Wait for the property data to be returned before executing any further code
    const savedPropertyDoc: firebase.firestore.DocumentSnapshot = await savedPropertyRef.get();
    //Save all the property data as an object of type IRvProperty
    const propertyData = savedPropertyDoc.data() as IRvProperty;
    //Return the object holding the property data
    return propertyData;
  };

  //Get the data for a Tour and update the local state
  const fetchTourAndUpdateLocal = async () => {
    //Get the reference to the collection that holds the Tour data
    const savedTourRef: firebase.firestore.DocumentReference = toursCollection.doc(
      tourId
    );
    //Read the Tour data and save as DocumentSnapshot object; wait for data return to complete before executing further code
    const savedTourDoc: firebase.firestore.DocumentSnapshot = await savedTourRef.get();
    //Read all the data from the Tour's DocumentSnapshot, save it as an IRvTour object
    const tourData = savedTourDoc.data() as IRvTour;
    //Create the Promise objects used to resolve requests while getting Tour and property data
    const propertiesPromises: Promise<
      IRvProperty
    >[] = tourData.appointments.map(async apptmnt => {
      //Get the property id associated with an appointment
      const property = await getProperty(apptmnt.property_listing_id);
      //return the appointment's property id and scheduled time
      return {
        ...property,
        id: apptmnt.property_listing_id,
        time: apptmnt.time
      };
    });

    //Apply Promises to all Tour and property requests to ensure they resolve before continuing
    const properties = await Promise.all(propertiesPromises);
    //Update state of the app with up-to-date Tour data
    setLocalTour({
      ...tourData,
      properties
    });
  };
  useEffect(() => {
    //get tour//
    fetchTourAndUpdateLocal();

    return () => {
      //cleanup//
    };
  }, [tourId]);

  //return() creates the container div on-screen and applies styles, then displays the data of the local Tour 
  return (
    //The big container that wraps the whole app
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "relative"
      }}
    >
      {localTour && (
        <>
          {/*Create the div that holds the Google Map for the Tour*/}
          <div className="has-map">
            <GMap starting_adress={localTour.starting_address} properties={localTour.properties}></GMap>
          </div>

          {/*Create the div that holds the list of Tour destinations*/}
          <div className="has-points">
            <div className="has-points-inner-wrapper">
              {/*StartingPoint is the first location on a Tour;
              Each Tour must have at least this one, single StartingPoint*/}
              <StartingPoint address={localTour.starting_address.address} />
              {/* <PropertySummary property={localTour.starting_address} type="start" index={0} /> */}
              {localTour.properties.map((property, index) => {
                {/* PropertySummary holds the data for a specific property:
                    id, address, agent_name, agent_phone, time */}
                return <PropertySummary property={property} isReady={localTour.status === 'ready'} index={index + 1} onClick={ () => {
                  {/*set the URL for the current tour and/or property */}
                  const next = `/detail/${tourId}/${property.id}`;
                  {/* Push current URL onto end of browsing history array */}
                  history.push(next)
                } } />;
              })}
            </div>
          </div>
        </>
      )}
      {/*  */}
    </div>
  );
};
