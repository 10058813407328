import React, { useState, FormEvent, useContext, useEffect } from "react";
import Firebase, { FirebaseContext } from ".firebase";
import { IRvProperty } from "types";
import Button from "Components/Button/Button";
import Autocomplete from "react-google-autocomplete";
import {
  MainContainer,
  HeaderContainer,
  MainAreaContainer,
  FooterContainer
} from "modules/Layout/Layout.module";
import { initialState } from '../State/initialState'
const Field: React.FunctionComponent<{
  type: string;
  name: string;
  placeholder: string;
  onUpdate: any;
  className?: string;
  disabled?: any;
  value?: any;
  step?: any;
}> = ({ type, name, placeholder, onUpdate, className, disabled, value, step }) => {
  const classNames = `${className ? className : ""} reva-input`;
  const hasDisabled = Boolean(disabled);
  return (
    <input
      name={name}
      className={classNames}
      type={type}
      step={step}
      placeholder={placeholder}
      onChange={(e: FormEvent<HTMLInputElement>) => {
        onUpdate(name, e.currentTarget.value);
      }}
      disabled={hasDisabled}
      value={value}
    />
  );
};
const PropertyForm: React.FunctionComponent<{ onSubmit?: any; savedProperty: null | IRvProperty }> = ({
  onSubmit, 
  savedProperty
}) => {
  const { user, Firebase } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
  }>(FirebaseContext);
  const [validForm, setvalidForm] = useState(null as boolean | null);
  const [property, setProperty] = useState(initialState);
  const updateState = (field: string, value: any) => {
    const newProperty = {
      ...property
    } as any;
    newProperty[field] = value;
    setProperty(newProperty as IRvProperty);
  };
  const validInteger = (integer:any) => {
    try {
      const vI =  Math.round(integer)
      return vI || ''
    } catch (error) {
      return ''
    }
  }
  const validFloat = (float:any) => {
    if(isNaN(parseFloat(float))) {
      return ''
    }
    return parseFloat(float)
  }
  useEffect(() => {
    if (savedProperty) {
      setProperty(savedProperty)
    }
  }, [savedProperty])
  useEffect(() => {
    
    const {
      agent_name,
      agent_phone,
      address,
      unit,
      zip_code,
      type,
      price,
      number_of_bedrooms,
      number_of_bathrooms,
      sqft,
      place_id
    } = property;
    if (
      address &&
      agent_name &&
      agent_phone &&
      zip_code &&
      price &&
      type &&
      number_of_bedrooms &&
      number_of_bathrooms &&
      place_id &&
      sqft &&
      agent_name &&
      agent_phone
    ) {
      setvalidForm(true);
    } else {
      setvalidForm(false);
    }
  }, [property]);
  const attemptSaveForm = () => {
    if (validForm) {
      onSubmit(property);
    }
  };
  return (
    <MainContainer>
      <HeaderContainer>
        <h1>Add or edit details:</h1>
      </HeaderContainer>
      <MainAreaContainer>
        <div className="form-group reva-form-group property-form">
          <Field
            className="full"
            onUpdate={updateState}
            type="text"
            name="agent_name"
            placeholder="Listing Agent Name"
            value={property.agent_name || ''}
          />
          <Field
            className="full"
            onUpdate={updateState}
            type="text"
            name="agent_phone"
            placeholder="Agent's Phone"
            value={property.agent_phone || ''}
          />
          {
            (!savedProperty || !savedProperty.place_id ) && (
              <Autocomplete
                className="reva-input full"
                onPlaceSelected={(place: any) => {
                  setProperty({
                    ...property,
                    address: place.formatted_address,
                    place_id: place.place_id
                  } as IRvProperty);
                }}
                types={["address"]}
                componentRestrictions={{ country: "us" }}
              />
            )
          }
          
          <Field
            className="big"
            onUpdate={updateState}
            type="text"
            name="city"
            placeholder="City"
            value={property.city || ''}
          />
          <Field
            className="small"
            onUpdate={updateState}
            type="text"
            name="unit"
            placeholder="Apt/Unit"
            value={property.unit || ''}
          />
          <Field
            className="medium"
            onUpdate={updateState}
            type="text"
            name="state"
            placeholder="State"
            value={property.state || ''}
          />
          <Field
            className="medium"
            onUpdate={updateState}
            type="text"
            name="zip_code"
            placeholder="Zip Code"
            value={property.zip_code || ''}
          />
          <Field
            className="big"
            onUpdate={updateState}
            type="number"
            name="price"
            placeholder="Sales Price"
            value={validInteger(property.price)}

          />
          <Field
            className="small"
            onUpdate={updateState}
            type="text"
            name="type"
            placeholder="type"
            value={property.type || ''}
          />
          <Field
            className="small"
            onUpdate={updateState}
            type="number"
            name="number_of_bedrooms"
            placeholder="Beds"
            value={validInteger(property.number_of_bedrooms)}
          />
          <Field
            className="small"
            onUpdate={updateState}
            type="number"
            name="number_of_bathrooms"
            placeholder="Baths"
            step="any"
            value={validFloat(property.number_of_bathrooms)}
          />
          <Field
            className="medium"
            onUpdate={updateState}
            type="number"
            name="sqft"
            placeholder="sqft"
            value={validInteger(property.sqft)}
          />
          <br />
        </div>
      </MainAreaContainer>
      <FooterContainer>
        {validForm && (
          <div style={{ marginTop: "auto", marginBottom: 0 }}>
            <Button onClick={attemptSaveForm}>SAVE PROPERTY</Button>
          </div>
        )}
      </FooterContainer>
    </MainContainer>
  );
};

export default PropertyForm;
