import React, { useEffect, useState } from 'react'
import { FirebaseInstance } from '.firebase';

const useAuth = () => {
    const [authUser, setAuthUser] = useState(null as any);
    const [revaUser, setRevaUser] = useState(null as any);
    const attemptLoadProfile = () => {}
    useEffect(() => {
        const unsubscribe = FirebaseInstance.auth.onAuthStateChanged((user) => {
            setAuthUser(user || null);
        });
        attemptLoadProfile();
        return () => unsubscribe();
    }, [])
    // FirebaseInstance.auth
    return {user: authUser, revaUser} as { user: firebase.User, revaUser: firebase.firestore.DocumentData };
}

export default useAuth;