import React, { FunctionComponent, CSSProperties } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from 'Components/Button/Button';
const ModalViewPort = styled.div`
  display: flex;
  flex: 1;
  background-color: #FF6A6A;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  position: absolute;
  height: 100%;
  color: white;
  z-index: 99;
  h1 {
    margin-top: 3em;
  }
  img {
    margin: 2em;
  }
  .continue-preview {
    font-family: "Colfax-Medium", Arial, Helvetica, sans-serif;
    font-size: 18px;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    width: 250px;
    height: 60px;
    background: #ffffff;
    border-radius: 5px;
    color: black;
    align-items: center;
    text-decoration: none;
  }
  .foot {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: auto;
    margin-bottom: 1.5em;
  }
`;
const primaryButtonStyles: CSSProperties = {
  padding: '0 .2em',
  backgroundColor: '#FFF',
  color:  '#FF6A6A',
  width: '149px',
  height: '60px',
}
const secondaryButtonStyles: CSSProperties = {
  ...primaryButtonStyles,
  backgroundColor:  '#FF6A6A',
  color: '#FFF',
  border: 'solid 1px #FFF',
}
export const CancelModal: FunctionComponent<{ closeModalFn: any, cancellableTour: firebase.firestore.DocumentReference }> = ({ closeModalFn, cancellableTour }) => {
  const history = useHistory();
  const cancelTour = async () => {
    await cancellableTour.update({
      status: 'cancelled',
    })
    closeModalFn();
    history.replace('/tour-list');
  }
  return (
    <ModalViewPort>
      <h1>Are you sure you want to cancel this tour?</h1>
      <img src="/warning.png" width="171" height="171" />
      <div className="foot">
        <Button style={ primaryButtonStyles } onClick={cancelTour}> CANCEL TOUR </Button>
        <Button style={ secondaryButtonStyles } onClick={closeModalFn} > GO BACK </Button>
      </div>
    </ModalViewPort>
  );
};

export default CancelModal;
