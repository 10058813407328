import React, { FunctionComponent, useState, useEffect } from "react";
import addHours from "date-fns/addHours";
import setHours from "date-fns/setHours";
import getHours from "date-fns/getHours";
import setMinutes from "date-fns/setMinutes";
import format from "date-fns/format";
import styled from "styled-components";

const TimePickerWrapper = styled.div`
  margin-top: 3em;
  .picker_row {
    display: flex;
    margin: 9px 41px;
    height: 48px;
    align-items: center;
    .label {
      flex: 1;
      .title {
        font-family: "Colfax-Medium", Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }
      .subtitle {
        font-family: Colfax, Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .input {
      flex:1;
      margin: 0 8px;
      input {
        width: 150px;
        background: #ffffff;
        border: 2px solid #d5cfcf;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 11px 20px;
        font-family: Colfax;
        font-size: 16px;
        line-height: 19px;
        font-weight: bolder;
      }
    }
  }
`

const TourTimePick: FunctionComponent<{
  onTimeChange: any;
  startDate: Date;
}> = ({ onTimeChange, startDate }) => {
  const makeValidDate = (pDate: Date): Date => {
    let localDate = new Date(pDate);
    const hours = getHours(localDate);
    if (![0, 24].includes(hours)) return localDate;
    localDate = setHours(localDate, 8);
    return localDate;
  };
  const [fromDate, setFromDate] = useState(makeValidDate(startDate) as Date);
  const [toDate, setToDate] = useState(addHours(fromDate, 2) as Date);
  useEffect(() => {
    onTimeChange(fromDate, toDate);
  }, [fromDate, toDate])

  return (
    <TimePickerWrapper>
      <div className="picker_row">
        <div className="label">
          <div className="title">start at</div>
          <div className="subtitle">
            
          {format(startDate, "MMM")} {format(startDate, "dd")}, {format(startDate, "yyyy")}
          
            
          </div>
        </div>
        <div className="input">
          <input
              type="time"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const v: string | null = event.currentTarget.value;
                if (!v) return;
                const [hours, minutes] = v.split(":");
                const newFromDate = setMinutes(
                  setHours(fromDate, parseInt(hours)),
                  parseInt(minutes)
                );
                setFromDate(makeValidDate(newFromDate));
                setToDate(makeValidDate(addHours(newFromDate, 2)));
              }}
              value={format(fromDate, "kk:mm")}
            /></div>
      </div>
      <div className="picker_row">
        <div className="label">
          <div className="title">ends at</div>
        </div>
        <div className="input">
          <input
              type="time"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const v: string | null = event.currentTarget.value;
                if (!v) return;
                const [hours, minutes] = v.split(":");
                const newFromDate = setMinutes(
                  setHours(fromDate, parseInt(hours)),
                  parseInt(minutes)
                );
                setToDate(makeValidDate(newFromDate));
              }}
              min={format(fromDate, "kk:mm")}
              value={format(toDate, "kk:mm")}
              required
            />
        </div>
      </div>
    </TimePickerWrapper>

  );
};

export default TourTimePick;
