import { useHistory, Link, useLocation } from "react-router-dom";
import React, { FunctionComponent, useContext, useState, useEffect } from "react";
import Firebase, { FirebaseContext } from ".firebase";
import Navigation from "Components/Header/Navigation";
const Header: FunctionComponent = () => {
  const [visible, setVisible] = useState(false)
  const location = useLocation();
  useEffect(() => {
    setVisible(
      !location.pathname.includes('login') &&
      !location.pathname.includes('on-boarding')

    )
  }, [location])
  return (
    <>
        {
          visible && <Navigation />
        }
        
    </>
  );
};

export default Header;
