import axios from "axios";
const API_token = process.env.REACT_APP_DATAFINITY_API_KEY;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + API_token;
axios.defaults.headers.common['Content-Type'] = 'application/json';
export default axios.create({
  baseURL: "https://api.datafiniti.co/v4",
  headers: {
    'Authorization': 'Bearer ' + API_token,
    'Content-Type': 'application/json'
  }
});
