import React, { useContext } from "react";
import Firebase, { FirebaseContext } from ".firebase";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Switch,
  Route,
  RouteProps
} from "react-router-dom";
import { TourDetail } from "modules/TourDetails/view";

export default [
  // {
  //   path: "/detail/:id",
  //   name: "Tour Details",
  //   component: TourDetail,
  //   exact: true
  // }
];

export const TourDetailRouter = () => {
  const { user, Firebase } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
  }>(FirebaseContext);
  return (
    <Route
      exact
      path={["/detail/:tourId"]} 
      render={() => {
        return user ? <TourDetail /> : <Redirect to={{ pathname: "/login" }} />;
      }}
    />
  );
};
