import React, { FunctionComponent } from "react";
import { match } from "react-router-dom";
import styled, { css } from "styled-components";
export const MainContainer = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;
export const HeaderContainer = styled.div`
  margin-top: 2.5rem;
  margin-left: 20px;
  margin-right: 20px;
`;
export const FooterContainer = styled.div`
  display: flex;
  padding-bottom: 1.5rem;
  justify-content: center;
`;
export const MainAreaContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
  min-height: 0px; /* needs vendor prefixes */
`;
export const Layout: FunctionComponent<{
  match?: match;
  header?: JSX.Element;
  footer?: JSX.Element;
}> = ({ match, children, header, footer }) => {
  return (
    <MainContainer className="mainContainer">
      <MainAreaContainer className="mainArea">{children}</MainAreaContainer>
    </MainContainer>
  );
};
