import styled from "styled-components";
const MainPink = "#FF12CB";
const Red = "#FF0000";
export const TourRow: any = styled.div`
  font-family: Colfax, Arial, Helvetica, sans-serif;
  display: flex;
  height: 73px;
  align-items: center;
  margin: 0;
  margin-top: 20px;
  max-width: 576px;
`;
TourRow.Date = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  color: #000000;
  .month {
    font-size: 16px;
    line-height: 19px;
  }
  .day {
    font-size: 20px;
    line-height: 24px;
  }
`;
TourRow.Details = styled.div`
  display: flex;
  flex: 1;
  margin-left: 10px;
  background: #f7f7f7;
  border-radius: 3px 0px 0px 3px;
  padding: 11px 16px;
  align-items: center;
  .info {
    &__title {
      font-family: "Colfax-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
      font-size: 16px;
      line-height: 19px;
      font-weight: bolder;
    }
    &__desc {
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
    }
    &__status {
      font-size: 10px;
      line-height: 12px;
      text-transform: capitalize;
      &::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
        background-color: MainPink;
        margin-right: 8px;
      }
      &.scheduling {
        &::before {
          background-color: ${MainPink};
        }
      }
      &.cancelled {
        &::before {
          background-color: ${Red};
        }
      }
      &.draft {
        &::before {
          background-color: #ffcc15;
        }
      }
      &.ready {
        &::before {
          background-color: #00ed34;
        }
      }
    }
  }
  .action {
    font-family: "Colfax-Bold", Arial, Helvetica, sans-serif;
    background: #ffffff;
    width: 104px;
    height: 30px;
    font-size: 12px;
    line-height: 14px;
    box-sizing: border-box;
    border-radius: 5px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 1rem;
    cursor: pointer;
    &.scheduling {
      color: #000;
      border: 1px solid #000;
    }
    &.cancelled {
      color: #c7c7c7;
      background-color: #eee;
      border: 1px solid #eee;
    }
    &.draft {
      color: #fff;
      background-color: #000;
      border: 1px solid #000;
    }
    &.ready {
      color: ${MainPink};
      border: 1px solid ${MainPink};
    }
  }
`;
