import React, {
  FunctionComponent,
  useState,
  useContext,
  useEffect
} from "react";
import isBefore from "date-fns/isBefore";
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import addHours from "date-fns/addHours";
import setDate from "date-fns/setDate";
import NewTourContext from "../context";
import { IRvTour } from "types";
import { useHistory } from "react-router";
import Firebase, { FirebaseContext, FirebaseInstance } from ".firebase/";
import Button from "Components/Button/Button";
import TourDatePick from "../Components/Datepick";
import TourTimePick from "../Components/Timepick";
import {
  MainContainer,
  FooterContainer,
  MainAreaContainer
} from "modules/Layout/Layout.module";

const TourDate: FunctionComponent = () => {
  const history = useHistory();
  const { user, Firebase } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
  }>(FirebaseContext);
  const { newTour, setNewTour } = useContext<{
    newTour: IRvTour | null;
    setNewTour: React.Dispatch<React.SetStateAction<IRvTour | null>>;
  }>(NewTourContext);
  const [startDate, setStartDate] = useState(addHours(new Date(), 48) as Date);
  const [endDate, setEndDate] = useState(null as Date | null);
  const [validDates, setValidDates] = useState(null as boolean | null);
  useEffect(() => {
    const getValidStatus = (): boolean => {
      if (!startDate || !endDate) return false;
      return isBefore(startDate, endDate);
    };
    setValidDates(getValidStatus());
  }, [startDate, endDate]);
  const attemptSaveTour = async () => {
    if (!startDate || !endDate) return;
    setNewTour(null);
    const savedTourRef: firebase.firestore.DocumentReference = await Firebase.db
      .collection("tours")
      .add({
        ...newTour,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        user_id: user.uid,
        status: "draft",
        pdf_path: null,
        startedTourCreation: new Date().toISOString()
      });
    history.push(`/edit/${savedTourRef.id}`);
  };
  return (
    <MainContainer>
      <MainAreaContainer>
          <TourDatePick
            onDateChange={(dayOfMonth: number) => {
              setStartDate(setDate(startDate, dayOfMonth));
              setEndDate(setDate(endDate || startDate, dayOfMonth));
            }}
          />
          <div>
            <div className="date"></div>
            <div className="timepickerss">
              <TourTimePick
                startDate={startDate}
                onTimeChange={(fromTime: Date, toTime: Date) => {
                  const newStartDate = setMinutes( setHours(startDate, getHours(fromTime)), getMinutes(fromTime))
                  const newEndDate = setMinutes( setHours(endDate || startDate, getHours(toTime)), getMinutes(toTime))
                  setStartDate(newStartDate);
                  setEndDate(newEndDate);
                }}
              />
            </div>
          </div>
      </MainAreaContainer>
      <FooterContainer>
        {validDates && (
          <Button
            onClick={() => {
              attemptSaveTour();
            }}
          >
            SAVE TOUR HOURS
          </Button>
        )}
      </FooterContainer>
    </MainContainer>
  );
};

export default TourDate;
