import React, { FunctionComponent, CSSProperties } from "react";
import Calendar from "react-calendar";
import addHours from "date-fns/addHours";
import {
  MainContainer,
  HeaderContainer,
  MainAreaContainer
} from "modules/Layout/Layout.module";
import getDate from "date-fns/getDate";

const mainAreaStyles = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "flex-start",
  paddingTop: "2em"
} as CSSProperties;
const TourDatePick: FunctionComponent<{ onDateChange: any; }> = ({
  onDateChange
}) => {
  const minDate = addHours(new Date(), 48) as Date;
  return (
    <MainContainer>
      <HeaderContainer>
        <h1>Great, what day should this tour happen? </h1>
      </HeaderContainer>
      <MainAreaContainer style={mainAreaStyles}>
        <Calendar
          onChange={(v: Date | Date[]) => {
            const dayOfMonth = getDate(v as Date)
            onDateChange(dayOfMonth);
          }}
          minDate={minDate}
        />
      </MainAreaContainer>
    </MainContainer>
  );
};

export default TourDatePick;
