import React from "react";
import { IRvTour, IRvProperty, IRvStartingPoint } from "../../../../types";
import { Property } from "./PropertySummary.styles";
import fromUnixTime from "date-fns/fromUnixTime";
import format from "date-fns/format";

const ordinal_suffix_of = (i: number) => {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "ST";
  }
  if (j === 2 && k !== 12) {
    return i + "ND";
  }
  if (j === 3 && k !== 13) {
    return i + "RD";
  }
  return i + "TH";
};
const PropertySummary: React.FunctionComponent<{
  property: IRvProperty;
  index: number;
  onClick?: any;
  isReady?: boolean | null;
}> = ({ property, index, onClick, isReady = null }) => {
  const { id, address, agent_name, agent_phone, time } = property;
  const getDateFromUnixTime = (): Date => {
    try {
      return fromUnixTime(time.seconds)
    } catch (error) {
      return new Date();
    }
  }
  const cDate: Date = getDateFromUnixTime();
  return (
    <Property onClick={ onClick }>
      <div className="has-dots"></div>
      <div className="info-wrapper">
        <div className="has-info">
          <div className="stop-number">
            <span>{ordinal_suffix_of(index)} stop</span>
          </div>
          <div className="address"> {address} </div>

          <div>
            {agent_name && (
              <div className="agent-name">
                Listing Agent: {agent_name}
                {/* BRE #{agent.BRE} */}
              </div>
            )}
            {
              isReady && (
                <div className="tour-date">{format(cDate, "p")}</div>
              )
            }
            {agent_phone && (
              <div className="agent-contact">
                {/* <span>{agent.email}</span> */}
                <span>{agent_phone}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Property>
  );
};

export default PropertySummary;
