import React from "react";
import {  IRvStartingPoint } from "../../../types";
import { Property } from "./PropertySummary/PropertySummary.styles";

export const StartingPoint: React.FunctionComponent<{
  address: string;
}> = ({ address }) => {
  return (
    <Property isStartingPoint>
      <div className="has-dots"></div>
      <div className="info-wrapper">
        <div className="has-info">
          <div className="stop-number">
            <span>Starting Point</span>
          </div>
          <div className="address"> {address} </div>
        </div>
      </div>
    </Property>
  );
};

export default StartingPoint;
