import React, { useContext, useState, FormEvent, useEffect } from "react";
import Firebase, { FirebaseContext } from ".firebase";
import { useHistory } from "react-router";
import Button from "Components/Button/Button";

const Company: React.FunctionComponent = () => {
  const { user, Firebase, revaUser, setRevaUser } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
    revaUser: firebase.firestore.DocumentData;
    setRevaUser: React.Dispatch<any>;
  }>(FirebaseContext);
  const history = useHistory();
  const [localUser, setLocalUser] = useState({
    company: null,
    dre_number: null
  } as { company: string | null; dre_number: string | null });
  const attemptUpdateUser = async (): Promise<
    firebase.firestore.DocumentReference
  > => {
    const revaUserRef: firebase.firestore.DocumentReference = Firebase.db
      .collection("users")
      .doc(revaUser.id);
    await revaUserRef.update({
      ...localUser
    });
    return revaUserRef;
  };
  const attemptSaveProfile = async () => {
    try {
      await attemptUpdateUser();
      setRevaUser({
        id: revaUser.id,
        ...revaUser,
        ...localUser,
      })
      return history.push("/tour-list");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <h1>Whats your Brokerage Company name and your DRE number?</h1>
      <div className="form-group reva-form-group">
        <input
          className="reva-input"
          type="text"
          placeholder="Company name"
          onChange={(e: FormEvent<HTMLInputElement>) => {
            setLocalUser({ ...localUser, company: e.currentTarget.value });
          }}
        />
        <input
          className="reva-input"
          type="text"
          placeholder="DRE number"
          onChange={(e: FormEvent<HTMLInputElement>) => {
            setLocalUser({ ...localUser, dre_number: e.currentTarget.value });
          }}
        />
        <Button onClick={attemptSaveProfile}>CONTINUE</Button>
      </div>
    </>
  );
};

export default Company;
