// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebaseConfig from "./config";
import * as app from "firebase/app";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

class Firebase {
  public app: firebase.app.App;
  public db: firebase.firestore.Firestore;
  public auth: firebase.auth.Auth;
  private googleProvider: firebase.auth.GoogleAuthProvider;
  private fbProvider: firebase.auth.FacebookAuthProvider;
  constructor() {
    this.app = app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.fbProvider = new app.auth.FacebookAuthProvider();
    this.db = app.firestore();
  }
  public async register(name: string, email: string, password: string) {
    const newUser = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    );
    if (!newUser.user) throw "Error registering user";
    return await newUser.user.updateProfile({
      displayName: name
    });
  }
  public async login(email: string, password: string) {
    return await this.auth.signInWithEmailAndPassword(email, password);
  }
  public loginGoogle(): Promise<firebase.auth.UserCredential> {
    return this.auth.signInWithPopup(this.googleProvider);
  }
  public loginFacebook(): Promise<firebase.auth.UserCredential> {
    return this.auth.signInWithPopup(this.fbProvider);
  }
  public async logout() {
    return await this.auth.signOut();
  }
  public functions(): firebase.functions.Functions {
    return app.functions();
  }
}

export default Firebase;
