import React, { FunctionComponent, useContext } from "react";
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";
import styled from "styled-components";
import { IRvProperty, IRvTour } from "types";
import EditTourContext from "../context";


const PropertyDetails = styled.div`
  display: flex;
  height: 100px;
  background: #F7F7F7;
  border-radius: 10px;
  margin: 0 10px;
  margin: 10px 0;
  overflow: hidden;
  position: relative;
  .property-image {
    width: 90px;
    height: 100px;
    background-color: #f8eadf;
    background-image: url("/sample_house.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .property-info {
    padding-top: 8px;
    padding-left: 23px;
    .header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 170px;
      overflow: hidden;
      .price {
        font-family: "Colfax-Medium";
        font-size: 24px;
        line-height: 29px;
      }
      .details {
        display: flex;
        font-size: 14px;
        line-height: 17px;
        .has-color {
          border-left: solid 2px;
          border-left-color: currentcolor;
          padding-left: 2px;
          margin-left: 3px;
        }
        .has-border {
          border-left: solid 2px;
          margin-left: 3px;
          padding-left: 3px;
        }
        strong {
          font-family: "Colfax-Medium";
        }
      }
    }
    .address {
      font-family: "Colfax-Medium";
      font-size: 14px;
      line-height: 17px;
      margin: 6px 0;
      max-width: 170px;
      overflow: hidden;
    }
    .type {
      font-size: 14px;
      line-height: 17px;
      position: absolute;
      top: 17px;
      right: 12px;
      max-width: 93px;
      overflow: hidden;
    }
    .editIcon {
      position: absolute;
      bottom: 8px;
      right: 12px;
    }
  }
`;
const formatMoney = (ammount: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  }).format(ammount);
};
const PropertyItem: FunctionComponent<{ property: IRvProperty; activeTour: IRvTour | null}> = ({
  property, activeTour
}) => {
  const image = property.image;
  // const image = "/sample_house.png";
  return (
    <PropertyDetails>
      {
        !property.image && <div className="property-image"></div>
      }
      {
        property.image && (
          <div
            className="property-image"
            style={{backgroundImage: `url(${image})`}}>
          </div>
        )
      }
      <div className="property-info">
        <div className="header">
          <div className="price">{formatMoney(property.price)}</div>
          <div className="details">
            <div className="beds">{property.number_of_bedrooms} <strong>bds</strong></div>
            <div className="baths has-border">{property.number_of_bathrooms} <strong>ba</strong></div>
            <div className="sqft has-border">{property.sqft} <strong>sqft</strong></div>
          </div>
        </div>
        <div className="address">{property.address}</div>
        <div className="type">{property.type} for sale</div>
        <div className="editIcon"> 
          {
            activeTour && activeTour.status !== 'ready' && (<Link to={`/edit/${activeTour.id}/edit_property/${property.id}`} className="continue-preview">
            <EditIcon style={{ fontSize: 15, color: "black"}} className="sizeSmall"  />
          </Link> )
          }
        </div>
      </div>
    </PropertyDetails>
  );
};

export default PropertyItem;
