import React, { useContext, useState, FormEvent, useEffect } from "react";
import Firebase, { FirebaseContext } from ".firebase";
import { useHistory } from "react-router";
import Button from "Components/Button/Button";

const Profile: React.FunctionComponent = () => {
  const { user, Firebase, revaUser, setRevaUser } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
    revaUser: firebase.firestore.DocumentData;
    setRevaUser: React.Dispatch<any>;
  }>(FirebaseContext);
  const history = useHistory();
  const [localUser, setLocalUser] = useState({
    first_name: null,
    last_name: null
  } as { first_name: string | null; last_name: string | null });
  const attemptUpdateUser = async (): Promise<
    firebase.firestore.DocumentReference
  > => {
    const revaUserRef: firebase.firestore.DocumentReference = Firebase.db
      .collection("users")
      .doc(revaUser.id);
    await revaUserRef.update({
      ...localUser
    });
    return revaUserRef;
  };
  const attemptSaveUser = async (): Promise<
    firebase.firestore.DocumentReference
  > => {
    return Firebase.db.collection("users").add({
      provider_id: user.uid,
      ...localUser
    });
    // Firebase.db.doc(use)
  };
  useEffect(() => {
    if (revaUser) {
      const { last_name, first_name, dre_number, company } = revaUser;
      let redirect = "";
      if (last_name && first_name) redirect = "on-boarding/company";
      if (redirect && dre_number && company) redirect = "tour-list";
      if (redirect) {
        history.replace(redirect);
      }
    }
  }, [revaUser]);
  const attemptSaveProfile = async () => {
    try {
      let savedRevaUser: firebase.firestore.DocumentReference | null = null;
      savedRevaUser = revaUser
        ? await attemptUpdateUser()
        : await attemptSaveUser();
      setRevaUser({
        id: savedRevaUser.id,
        ...revaUser,
        ...localUser
      });
      return history.push("tour-list");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <h1>
        <strong>Hey! I'm Reva.</strong>
        I'll help you create a property tour.
        Ready to go?
      </h1>
      <div className="form-group reva-form-group">
        <input
          type="text"
          className="reva-input"
          placeholder="First name"
          onChange={(e: FormEvent<HTMLInputElement>) => {
            setLocalUser({ ...localUser, first_name: e.currentTarget.value });
          }}
        />
        <input
          type="text"
          className="reva-input"
          placeholder="last name"
          onChange={(e: FormEvent<HTMLInputElement>) => {
            setLocalUser({ ...localUser, last_name: e.currentTarget.value });
          }}
        />
        <Button onClick={attemptSaveProfile}>CONTINUE</Button>
      </div>
    </>
  );
};

export default Profile;
