import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext
} from "react";
import {
  BrowserRouter as Router,
  Link,
  useHistory,
  useParams,
  match
} from "react-router-dom";
import format from "date-fns/format";
import AddIcon from '@material-ui/icons/Add';
import Firebase, { FirebaseContext } from ".firebase";
import { IRvTour, IRvProperty, IRvStartingPoint, IRvAppointment } from "types";
import EditTourContext from "../context";
import PropertyItem from "../Components/PropertyItem";
import styled from "styled-components";
import Button from "Components/Button/Button";
import { SuccessModal } from "../Components/SuccessModal";
import { CancelModal } from "../Components/CancelModal";
import {
  MainContainer,
  HeaderContainer,
  MainAreaContainer,
  FooterContainer
} from "modules/Layout/Layout.module";
import { sortAppointments, appt } from "modules/ToursList/Hooks/useTourList.hook";

const ScrollableDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 540px;
  flex-grow: 0;
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  margin-top: 1em;
`;
export const PreviewTour: React.FunctionComponent<{
  match: match;
  tourId: string;
}> = ({ tourId, match }) => {
  const history = useHistory();
  const { user, Firebase } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
  }>(FirebaseContext);
  
  const { activeTour, setActiveTour } = useContext<{
    activeTour: IRvTour | null;
    setActiveTour: React.Dispatch<React.SetStateAction<IRvTour | null>>;
  }>(EditTourContext);
  const toursCollection = Firebase.db.collection("tours");
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const savedTourRef: firebase.firestore.DocumentReference = toursCollection.doc(
    tourId
  );
  const finishEditing = async () => {
    const newTourData: any = {
      endedTourCreation: new Date().toISOString(),
      status: "scheduling"
    }
    if (activeTour && !activeTour.starting_address) {
      const appointments: IRvAppointment[] = sortAppointments(activeTour.appointments)
      const property = await getProperty(appointments[0].property_listing_id)
      newTourData.starting_address = {
        address: property.address,
        place_id: property.place_id
      }
    }
    await savedTourRef.update(newTourData);
    setShowModal(true);
  };
  const to = activeTour ? `/edit/${activeTour.id}/add_property` : "/";
  const propertiesCollection = Firebase.db.collection("properties");

  const [localTour, setLocalTour] = useState(null as null | IRvTour);
  const getProperty = async (propertyId: string) => {
    const savedPropertyRef: firebase.firestore.DocumentReference = propertiesCollection.doc(
      propertyId
    );
    const savedPropertyDoc: firebase.firestore.DocumentSnapshot = await savedPropertyRef.get();
    const propertyData = savedPropertyDoc.data() as IRvProperty;
    return propertyData;
  };
  useEffect(() => {
    //get tour//
    const fetchTourAndUpdateLocal = async () => {
      const savedTourDoc: firebase.firestore.DocumentSnapshot = await savedTourRef.get();
      const tourData = savedTourDoc.data() as IRvTour;
      const propertiesPromises: Promise<
        IRvProperty
      >[] = tourData.appointments.map(async apptmnt => {
        const property = await getProperty(apptmnt.property_listing_id);
        return property;
      });
      const properties = await Promise.all(propertiesPromises);
      setLocalTour({
        ...tourData,
        properties
      });
    };
    fetchTourAndUpdateLocal();

    return () => {
      //cleanup//
    };
  }, [tourId]);
  const humanDate = (pDate: Date) => {
    return format(pDate, "EEEE, LLLL do");
  };
  const humanTime = (pDate: Date) => {
    return format(pDate, "p").toLocaleLowerCase();
  };
  const attemptCancelTour = () => {
    savedTourRef.update({
      status: "cancelled"
    });
  };
  return (
    <MainContainer>
      {!showModal && localTour && localTour.properties.length && (
        <>
          <HeaderContainer>
            <h1>
              OK! I'll be scheduling a tour of
              <span className="title_highlight">
                {`${localTour.properties.length}`} properties
              </span>
              for
              <span className="title_highlight">
                {`${localTour.client_name}`}
              </span>
              on
              <span className="title_highlight">
                {`${humanDate(new Date(localTour.startDate))}`}
              </span>
              between
              <span className="title_highlight">
                {`${humanTime(new Date(localTour.startDate))}`}
              </span>
              and
              <span className="title_highlight">
                {`${humanTime(new Date(localTour.endDate))}`}
              </span>
              Please review it:{" "}
            </h1>
          </HeaderContainer>

          <MainAreaContainer>
            <div>
              <ScrollableDiv>
                <div style={{ paddingBottom: "200px" }}>
                  {localTour.properties.map((property) => (
                    <PropertyItem property={property} activeTour={activeTour} />
                  ))}
                  {
                    activeTour && (
                      <Link to={`/edit/${activeTour.id}/add_property/`} className="add_property_link">
                        <div className="add_property">
                        <AddIcon className="plus_sign" style={{ fontSize: 40, color: "#FF12CB", marginLeft: 27}} /> 
                        <span className="plus_text">ADD PROPERTY</span>
                        </div>
                      </Link>
                    )
                  }

                </div>
              </ScrollableDiv>
            </div>
          </MainAreaContainer>
          <FooterContainer>
            <div className="sticky-bottom-preview">
              <h1>Should I get started on scheduling this tour? </h1>
              <Button onClick={finishEditing}> GO REVA </Button>
              <button
                className="cancel-tour"
                onClick={() => setShowCancelModal(true)}
              >
                Cancel Tour
              </button>
            </div>
          </FooterContainer>
        </>
      )}
      {showModal && <SuccessModal />}
      {showCancelModal && (
        <CancelModal
          closeModalFn={() => {
            setShowCancelModal(false);
          }}
          cancellableTour={savedTourRef}
        />
      )}
    </MainContainer>
  );
};
export default PreviewTour;
