import React, {
  FunctionComponent,
  FormEvent,
  useContext,
  useState,
  useEffect
} from "react";
import NewTourContext from "../context";
import { IRvTour } from "types";
import { useHistory } from "react-router";
import Button from "Components/Button/Button";
import {
  MainAreaContainer,
  HeaderContainer,
  FooterContainer,
  MainContainer
} from "modules/Layout/Layout.module";

const ClientView: FunctionComponent = () => {
  const history = useHistory();
  const { newTour, setNewTour } = useContext<{
    newTour: IRvTour | null;
    setNewTour: React.Dispatch<React.SetStateAction<IRvTour | null>>;
  }>(NewTourContext);
  const [startedCreation, setStartedCreation] = useState(new Date());
  useEffect(() => {
    setStartedCreation(new Date());
  }, []);
  return (
    <MainContainer>
      <HeaderContainer>
        <h1>Great, let's create a tour! To start, who is this tour for?</h1>
      </HeaderContainer>
      <MainAreaContainer>
        <div className="form-group reva-form-group">
          <input
            type="text"
            className="reva-input"
            placeholder="client's name"
            onChange={(e: FormEvent<HTMLInputElement>) => {
              const toSave = newTour
                ? { ...newTour, client_name: e.currentTarget.value }
                : { client_name: e.currentTarget.value };
              setNewTour(toSave as IRvTour);
            }}
            required
          />
        </div>
      </MainAreaContainer>
      <FooterContainer>
        <Button
          disabled={!newTour || !newTour.client_name}
          onClick={() => {
            history.push("/new-tour/select-date");
          }}
        >
          CONTINUE
        </Button>
      </FooterContainer>
    </MainContainer>
  );
};

export default ClientView;
