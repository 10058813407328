import React, { useContext } from "react";
import Firebase, { FirebaseContext } from ".firebase";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Switch,
  Route
} from "react-router-dom";
import { EditTour } from "modules/EditTour/index";

export default [
  {
    path: "/edit/:tourId",
    name: "Edit Tour",
    component: EditTour,
    exact: true
  }
];
export const EditTourRouter = () => {
  const { user, Firebase, revaUser } = useContext<{
    user: firebase.User;
    Firebase: Firebase;
    revaUser: any | null;
  }>(FirebaseContext);
  return (
    <Route
      path={["/edit/:tourId"]} 
      render={({match}) => {
        let response = <EditTour match={match} />;
        if (!user) response = <Redirect to={{ pathname: "/login" }} />;
        if (!revaUser) response = <Redirect to={{ pathname: "/on-boarding" }} />;
        return response
      }}
    />
  );
};
